import SmoothScroll from "smooth-scroll";

new SmoothScroll('a[href*="#"]', {
  speed: 900,
  speedAsDuration: true,
});

function appearLineButton() {
  const lineButton = document.querySelector('.line-link');
  const wholeheight = Math.max.apply( null, [document.body.clientHeight , document.body.scrollHeight, document.documentElement.scrollHeight, document.documentElement.clientHeight]);
  const displayPoint = wholeheight / 3;
  const footerHeight = document.getElementById('footer').clientHeight;
  const vanishingPoint = wholeheight - footerHeight;
  const scrollPosition = window.scrollY + window.innerHeight;

  if(displayPoint <= scrollPosition) {
    lineButton.classList.remove('line-link--vanish');
    lineButton.classList.add('line-link--display');
  }

  if(window.scrollY == 0) {
    lineButton.classList.remove('line-link--display');
    lineButton.classList.add('line-link--vanish');
    console.log(window.scrollY);
  }
  
  if(vanishingPoint <= scrollPosition) {
    lineButton.classList.remove('line-link--display');
    lineButton.classList.add('line-link--vanish');
  }
}

window.addEventListener('scroll', appearLineButton);